<template>
  <v-autocomplete
    v-model="localValue"
    :items="insurers"
    item-text="name"
    item-value="value"
    hide-no-data
    :label="$t('labels.insurer')"
    required
    :error-messages="errors"
    clearable
  />
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { CustomFieldMixin } from '@argon/app/mixins'
import { NAMESPACE } from '../store'
export default {
  name: 'InsurerField',
  mixins: [CustomFieldMixin],
  methods: {
    ...mapActions(NAMESPACE, ['listInsurers'])
  },
  computed: {
    ...mapState(NAMESPACE, ['insurers'])
  },
  created() {
    this.listInsurers()
  }
}
</script>
