<template>
  <VModalWidget :submit="submit" :dismiss="dismiss" :submitting="submitting" :submit-title="$t('labels.save')">
    <template v-slot:title>{{ $t('labels.contractNumber') }}</template>
    <template v-slot:content>
      <FormErrors :show="showResourceErrors" :errors="resourceErrors" />
      <v-form>
        <v-text-field
          :label="$t('labels.contractNumber')"
          v-model.trim="form.meta.contract_number"
          :errors="numberErrors"
        />
        <InsurerField v-model="form.insurer" />
      </v-form>
    </template>
  </VModalWidget>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { VModalWidget, BaseForm, FormErrors } from '@argon/app/components'
import { NAMESPACE } from '@argon/evo/entities/store'
import DealInfoModalMixin from '../mixins/DealInfoModalMixin'
import InsurerField from '@/insurance/components/InsurerField'

export default {
  name: 'ContractNumberModal',
  extends: BaseForm,
  mixins: [DealInfoModalMixin],
  components: {
    VModalWidget,
    FormErrors,
    InsurerField
  },
  validations: {
    form: {
      insurer: {},
      meta: {
        contract_number: { required }
      }
    }
  },
  data: () => ({
    form: {
      insurer: null,
      meta: {
        contract_number: null
      }
    }
  }),
  computed: {
    contractNumber() {
      return this.boardItem ? this.boardItem.entity.meta.contract_number : null
    },
    numberErrors() {
      const errors = []
      if (!this.$v.form.meta.contract_number.$dirty) return errors
      !this.$v.form.meta.contract_number.required && errors.push(this.$t('required'))
      this.getServerErrors('contract_number', errors)
      return errors
    }
  },
  methods: {
    ...mapActions(NAMESPACE, ['patchEntity']),
    submit() {
      if (this.$v.form.$invalid) {
        return this.$v.form.$touch()
      }
      this.submitting = true
      let payload = {
        proxy: this.entityURL,
        url: this.baseURL,
        data: this.form
      }
      this.patchEntity(payload)
        .then((resp) => {
          this.dismiss()
        })
        .catch(this.setServerErrors)
        .finally(() => {
          this.submitting = false
        })
    }
  },
  created() {
    this.form.insurer = this.boardItem.entity.insurer
    this.form.meta.contract_number = this.boardItem.entity.meta.contract_number
  }
}
</script>
